<template>
	<div
		@click="openModal"
		class="d-flex align-items-center justify-content-center flex-column callHelp"
	>
		<div class="mb-2"></div>
		<p>Llámanos</p>
	</div>
</template>

<script>
import MCallUs from '@/modals/m-call-us';

export default {
	name: 'call-help',
	methods: {
		openModal() {
			this.$store.dispatch('modal/open', MCallUs);
		},
	},
};
</script>

<style lang="scss" scoped>
.callHelp {
	position: absolute;
	top: 10px;
	right: 10px;
	height: 100px;
	width: 100px;
}
.callHelp div {
	height: 42px;
	width: 42px;
	background-image: url(~@/assets/images/call.svg);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}
.callHelp p {
	font-size: 10px;
}
@media screen and (max-width: 580px) {
	.callHelp {
		display: none !important;
	}
}
@media screen and (max-device-width: 480px) {
	.callHelp {
		display: none !important;
	}
}
</style>
