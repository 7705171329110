<template>
	<div>
		<div class="modal-header">
			<button
				data-test-id="close-button"
				@click="closeModal"
				aria-label="Close"
				class="close"
				type="button"
			>
				<span aria-hidden="true">×</span>
			</button>
		</div>
		<div class="modal-body">
			<h5 class="text-center mb-4">¿Tienes alguna pregunta en el proceso?</h5>
			<p class="text-justify">
				Ponte en contacto con nosotros y resolveremos cualquier duda que tengas en el proceso de
				contratación. Estamos para ayudarte.
			</p>
			<div class="d-flex callHelp justify-content-center mt-4">
				<img
					alt
					src="@/assets/images/call.svg"
				/>
				<span class="tfLinkBF">{{ telephone }}</span>
			</div>
			<p class="text-center font-weight-bold text-secondary mt-4">HORARIO</p>
			<p class="text-center font-weight-bold text-secondary mt-1">
				Lunes a Viernes de 08:30 a 20:00 h
				<br />
				Sábados de 09:00 a 14:00 h
			</p>
		</div>
		<div class="modal-footer justify-content-center"></div>
	</div>
</template>

<script>
export default {
	name: 'm-call-us',

	computed: {
		telephone({ $store }) {
			if ($store.state.app.isSAL) {
				return '900 101 817';
			}
			return '900 107 411 / 913 109 550';
		},
	},

	methods: {
		closeModal() {
			this.$store.dispatch('modal/close');
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-header span {
	font-size: 37px;
	color: #c9c9c9;
}
.modal-body {
	margin: 0 30px;
	padding: 0 15px;
}
.modal-body p {
	font-size: 14px;
}
.modal-body .callHelp {
	height: 40px;
}
.modal-body .callHelp img {
	width: 35px;
	height: 36px;
	margin-right: 10px;
}
.modal-body .callHelp span {
	font-size: 24px;
}
</style>
